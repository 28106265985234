var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 pl-4 pb-2 mb-4"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('p',{staticClass:"display-1 text--primary",domProps:{"innerHTML":_vm._s(_vm.$t('channels.heading'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$tc('channels.subheading', _vm.channels.length))}})]),_c('v-row',[_c('v-col',{staticClass:"pl-6 pr-6"},[_c('search',{ref:"searchComponent",attrs:{"value":_vm.search},on:{"input":function($event){_vm.search = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-2 ma-3 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.channels,"item-class":_vm.rowClasses,"search":_vm.search,"loading":_vm.$store.getters.getLoaders.channels,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.goToChannel},scopedSlots:_vm._u([{key:"item.ss_logo",fn:function(ref){
                  var item = ref.item;
return [(item.ss_id && item.ss_visible)?_c('v-avatar',{attrs:{"tile":"","size":"35"}},[_c('v-img',{attrs:{"contain":"","src":require(("@/assets/images/portale/" + (item.ss_id) + ".png")),"lazy-src":require(("@/assets/images/portale/" + (item.ss_id) + ".png")),"alt":"Channel-Logo"}})],1):_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{class:!item.ss_visible && 'text--disabled',attrs:{"color":_vm.$store.getters.getColors.secondary1}},[_vm._v(" mdi-image-area ")])],1)]}},{key:"item.ss_id",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ss_id))])]}},{key:"item.ss_textid",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ss_textid))])]}},{key:"item.ss_name",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.ss_name))])]}},{key:"item.ss_isterweitert",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.ss_isterweitert}})]}},{key:"item.ss_istkostenlos",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.ss_istkostenlos}})]}},{key:"item.ss_multiaccount",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.ss_multiaccount}})]}},{key:"item.ss_getsupportreport",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.ss_getsupportreport}})]}},{key:"item.ss_getbookings",fn:function(ref){
                  var item = ref.item;
return [_c('activated-deactivated-icon',{attrs:{"is-set":item.ss_getbookings}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }