<template>
    <v-icon x-small :color="(isSet ? $store.getters.getColors.primary : $store.getters.getColors.primaryOpacity05)">
        {{ (isSet ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank') }}
    </v-icon>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ActivatedDeactivatedIcon',
    props: {
        isSet: {
            type: [String, Number, Boolean],
            default: null,
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
        ]),
    },
}
</script>
