<template>
    <div class="w-100 pl-4 pb-2 mb-4">
        <v-card outlined>
            <v-card-text>
                <p class="display-1 text--primary" v-html="$t('channels.heading')"></p>
                <p v-html="$tc('channels.subheading', channels.length)"></p>
            </v-card-text>
            <v-row>
                <v-col class="pl-6 pr-6">
                    <search
                        :value="search"
                        ref="searchComponent"
                        @input="search = $event"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="channels"
                        :item-class="rowClasses"
                        :search="search"
                        :loading="$store.getters.getLoaders.channels"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 ma-3 cursor-pointer"
                        @click:row="goToChannel"
                    >
                        <template v-slot:item.ss_logo="{ item }">
                            <v-avatar
                                tile
                                v-if="item.ss_id && item.ss_visible"
                                size="35"
                            >
                                <v-img
                                    contain
                                    :src="require(`@/assets/images/portale/${item.ss_id}.png`)"
                                    :lazy-src="require(`@/assets/images/portale/${item.ss_id}.png`)"
                                    alt="Channel-Logo"
                                />
                            </v-avatar>
                            <v-avatar
                                v-else
                                size="35"
                            >
                                <v-icon
                                    :class="!item.ss_visible && 'text--disabled'"
                                    :color="$store.getters.getColors.secondary1"
                                >
                                    mdi-image-area
                                </v-icon>
                            </v-avatar>
                        </template>
                        <template v-slot:item.ss_id="{ item }">
                            <span>{{ item.ss_id }}</span>
                        </template>
                        <template v-slot:item.ss_textid="{ item }">
                            <span>{{ item.ss_textid }}</span>
                        </template>
                        <template v-slot:item.ss_name="{ item }">
                            <span>{{ item.ss_name }}</span>
                        </template>
                        <template v-slot:item.ss_isterweitert="{ item }">
                            <activated-deactivated-icon :is-set="item.ss_isterweitert"/>
                        </template>
                        <template v-slot:item.ss_istkostenlos="{ item }">
                            <activated-deactivated-icon :is-set="item.ss_istkostenlos"/>
                        </template>
                        <template v-slot:item.ss_multiaccount="{ item }">
                            <activated-deactivated-icon :is-set="item.ss_multiaccount"/>
                        </template>
                        <template v-slot:item.ss_getsupportreport="{ item }">
                            <activated-deactivated-icon :is-set="item.ss_getsupportreport"/>
                        </template>
                        <template v-slot:item.ss_getbookings="{ item }">
                            <activated-deactivated-icon :is-set="item.ss_getbookings"/>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import ActivatedDeactivatedIcon from '@/components/Allgemein/AktivInaktivSymbol.vue'
import Search from '@/components/Allgemein/Suche.vue'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'
import ChannelsService from '@/services/channels.service'

export default {
    name: 'Channels',
    components: { Search, ActivatedDeactivatedIcon },
    mixins: [socket],
    metaInfo: {
        title: 'Channels',
    },
    data() {
        return {
            channels: [],
            expanded: [],
            singleExpand: false,
            search: '',
        }
    },
    sockets: {
        async channelUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getChannels()
                await this.$store.dispatch('socketEventBanner', {
                    message: `${ data.user.fullname } changed channel data!`,
                    autoHide: true,
                })
            }
        },
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getUser',
            'getActAsMember',
        ]),
        headers: function () {
            return [
                {
                    text: '',
                    value: 'ss_logo',
                    sortable: false,
                    align: 'center',
                    divider: true,
                    width: '25px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.channelId'),
                    value: 'ss_id',
                    align: 'end',
                    divider: true,
                    width: '150px',
                },
                {
                    text: this.$i18n.t('common.labels.ids.textId'),
                    value: 'ss_textid',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.name'),
                    value: 'ss_name',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.content'),
                    value: 'ss_isterweitert',
                    align: 'center',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.free'),
                    value: 'ss_istkostenlos',
                    align: 'center',
                    divider: true,
                    width: '110px',
                },
                {
                    text: this.$i18n.t('common.labels.multiAccount'),
                    value: 'ss_multiaccount',
                    align: 'center',
                    divider: true,
                    width: '140px',
                },
                {
                    text: this.$i18n.t('common.labels.reporting'),
                    value: 'ss_getsupportreport',
                    align: 'center',
                    divider: true,
                    width: '130px',
                },
                {
                    text: this.$i18n.t('common.labels.getBookings'),
                    value: 'ss_getbookings',
                    align: 'center',
                    divider: true,
                    width: '150px',
                },
            ]
        },
    },
    methods: {
        rowClasses(item) {
            if (!item.ss_visible)
                return 'cursor-not-allowed text--disabled'
        },
        selectText(e) {
            e.target.setSelectionRange(0, this.search.length)
        },
        goToChannel(e, row) {
            const item = row.item

            if (item.ss_visible) {
                this.$router.push({ path: `/channel/${ item.ss_id }` })
            }
        },
        async getChannels() {
            await this.$store.dispatch('setLoaders', { channels: true })
            this.channels = await ChannelsService.getChannels()
            await this.$store.dispatch('setLoaders', { channels: false })
        },
    },
    async created() {
        await this.getChannels()
    },
}
</script>
